function removeDisconnectAircardModal() {
  const modal = document.getElementById('disconnect-aircard-modal');
  modal.classList.add('tw-hidden');
}
window.removeDisconnectAircardModal = removeDisconnectAircardModal;

function enableDisconnectButton() {
  const button = document.querySelector('#disconnect-aircard-modal .btn-action');
  button.classList.remove('tw-btn-disabled');
  button.classList.add('tw-btn-action-red');
}
window.enableDisconnectButton = enableDisconnectButton;

function disconnectAircard(controllerId) {
  const disconnectionReason = document.querySelector('#disconnect_aircard_reason .li-check').id.split('-')[1];
  window.$.ajax({
    url: `/controls/${controllerId}/disconnect_aircard`,
    data: { reason: disconnectionReason },
    dataType: 'script',
    type: 'PUT',
  });
}

function showDisconnectAircardModal(controllerId, withRemainingPlanTime, remainingPlanTime, subscriptionPlan) {
  const modal = document.getElementById('disconnect-aircard-modal');
  const button = modal.querySelector('.btn-action');
  if (withRemainingPlanTime === 'true') {
    modal.querySelector('.description').textContent = window.I18n.controls.edit.disconnect_aircard_modal.with_remaining_plan.description.replace('%{remaining_plan_time}', `${remainingPlanTime} ${parseInt(remainingPlanTime, 10) > 1 ? window.I18n.days.toLowerCase() : window.I18n.datetime.prompts.day.toLowerCase()}`).replace('%{subscription_plan}', subscriptionPlan);
  } else {
    modal.querySelector('.description').textContent = window.I18n.controls.edit.disconnect_aircard_modal.without_remaining_plan.description;
  }
  window.resetDropdown('#disconnect_aircard_reason', window.I18n.controls.edit.disconnect_aircard_modal.why_disconnect_it.placeholder);
  button.classList.add('tw-btn-disabled');
  button.classList.remove('tw-btn-action-red');
  button.onclick = () => disconnectAircard(controllerId);
  modal.classList.remove('tw-hidden');
}
window.showDisconnectAircardModal = showDisconnectAircardModal;

const controlNumZones = document.querySelector('#controls-edit input[name="control[num_zones]"]');

function toggleStepperNumZones(selector, action) {
  const button = document.querySelector(selector);
  if (action === 'disable') {
    button.classList.add('tw-pointer-events-none');
    button.classList.add('tw-text-gray-500');
    button.classList.remove('tw-text-blue-500');
  } else {
    button.classList.remove('tw-pointer-events-none');
    button.classList.remove('tw-text-gray-500');
    button.classList.add('tw-text-blue-500');
  }
}

function changeVirtualControllerNumZones(button) {
  let value = parseInt(controlNumZones.value, 10) || 0;
  value = button === 'plus' ? value + 1 : value - 1;
  if (value <= controlNumZones.min) {
    value = controlNumZones.min;
    toggleStepperNumZones('#num_zones_selector .btn-minus', 'disable');
  } else {
    toggleStepperNumZones('#num_zones_selector .btn-minus', 'enable');
  }
  if (value >= controlNumZones.max) {
    value = controlNumZones.max;
    toggleStepperNumZones('#num_zones_selector .btn-plus', 'disable');
  } else {
    toggleStepperNumZones('#num_zones_selector .btn-plus', 'enable');
  }
  controlNumZones.value = value;
}

window.changeVirtualControllerNumZones = changeVirtualControllerNumZones;

controlNumZones?.addEventListener('input', (e) => {
  let value = parseInt(e.target.value, 10) || 0;
  if (value <= controlNumZones.min) {
    value = controlNumZones.min;
    toggleStepperNumZones('#num_zones_selector .btn-minus', 'disable');
  } else {
    toggleStepperNumZones('#num_zones_selector .btn-minus', 'enable');
  }
  if (value >= controlNumZones.max) {
    value = controlNumZones.max;
    toggleStepperNumZones('#num_zones_selector .btn-plus', 'disable');
  } else {
    toggleStepperNumZones('#num_zones_selector .btn-plus', 'enable');
  }
  e.target.value = value;
});

function aircardWithRemainingTime() {
  if (window.confirm(window.I18n.controls.edit.activation_confirmation_message)) {
    const priorEndDate = document.getElementById('controller_destroy').dataset.subscriptionEndDate;
    const priorIccid = document.getElementById('controller_destroy').dataset.iccid;
    const iccid = document.querySelector('#update_aircard_controller #control_iccid').value;
    if (iccid) {
      window.$.ajax({
        url: `/api/v2/aircards/${iccid}`,
        dataType: 'json',
        type: 'GET',
        contentType: 'application/json; charset=utf-8',
        success(response) {
          if (response.last_subscription?.remaining_time) {
            const date = new Date(priorEndDate);
            if (date > Date.now() && priorIccid) {
              const priorDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
              const newDate = new Date(response.last_subscription.end_date);
              const newDateStr = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
              const description = window.I18n.controls.edit.aircard_with_remaining_time_modal.double_end_date.description.replace('%{prior_iccid}', priorIccid).replace('%{prior_end_date}', priorDate).replace('%{new_iccid}', iccid).replace('%{new_end_date}', newDateStr);
              document.querySelector('#replace_aircard_time .description').innerHTML = description;
              const button = document.querySelector('#replace_aircard_time .tw-btn-action-blue');
              button.textContent = window.I18n.controls.edit.aircard_with_remaining_time_modal.double_end_date.action_button;
              button.onclick = () => { window.open('https://support.weathermatic.com/hc/en-us/requests/new', '_blank'); };
            } else {
              const newDate = new Date(response.last_subscription.end_date);
              const newDateStr = `${newDate.getMonth() + 1}/${newDate.getDate()}/${newDate.getFullYear()}`;
              let description;
              if (priorIccid) {
                description = window.I18n.controls.edit.aircard_with_remaining_time_modal.one_end_date.description.replace('%{new_iccid}', iccid).replace('%{new_end_date}', newDateStr).replace('%{remaining_time}', response.last_subscription?.remaining_time);
              } else {
                description = window.I18n.controls.edit.aircard_with_remaining_time_modal.one_end_date.description_no_aircard.replace('%{new_iccid}', iccid).replace('%{new_end_date}', newDateStr).replace('%{remaining_time}', response.last_subscription?.remaining_time);
              }
              document.querySelector('#replace_aircard_time .description').innerHTML = description;
              const button = document.querySelector('#replace_aircard_time .tw-btn-action-blue');
              button.textContent = window.I18n.continue;
              button.onclick = () => { document.querySelector('#update_aircard_controller').submit(); };
            }
            document.getElementById('replace_aircard_time').classList.remove('tw-hidden');
          } else {
            document.querySelector('#update_aircard_controller').submit();
          }
        },
        error() {
          document.querySelector('#update_aircard_controller').submit();
        },
      });
    } else {
      document.querySelector('#update_aircard_controller').submit();
    }
  }
}
window.aircardWithRemainingTime = aircardWithRemainingTime;
