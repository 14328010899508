import Backbone from 'backbone';
import $ from 'jquery';

const InspectionZoneCommentsView = Backbone.View.extend({
  el: '#inspection-table',
  events: {
    'click .edit-zone-comment': 'popZoneCommentModal',
  },
  initialize() {
    console.log('Inspection Zone Comments initialized');
  },
  popZoneCommentModal(e) {
    const inspectionId = $('#inspection').data('inspection-id');
    const inspectionZoneId = e.currentTarget.querySelector('p').dataset.id;

    $.ajax({
      url: `/inspections/${inspectionId}/inspections_zones/${inspectionZoneId}/comments`,
      type: 'GET',
      dataType: 'script',
      data: { edit: !document.getElementById('controller_inspections-show') },
    });
  },
});
export default InspectionZoneCommentsView;
