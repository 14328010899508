import {
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  createGrid,
  GridOptions,
  IServerSideGetRowsParams,
  ModuleRegistry,
  PaginationModule,
  RowAutoHeightModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
  SetFilterModule,
  ValidationModule,
} from 'ag-grid-enterprise';

import Base64 from 'crypto-js/enc-base64.js';
import HmacSHA256 from 'crypto-js/hmac-sha256.js';

ModuleRegistry.registerModules([
  PaginationModule,
  ColumnsToolPanelModule,
  ColumnMenuModule,
  ContextMenuModule,
  ServerSideRowModelModule,
  SetFilterModule,
  ServerSideRowModelApiModule,
  RowAutoHeightModule,
  ValidationModule /* Development Only */,
]);

let gridApi = null;
const gridOptions: GridOptions = {
  columnDefs: [
    {
      headerName: 'Alert Id',
      field: 'id',
      maxWidth: 90,
      sortable: false,
      wrapHeaderText: true,
      cellRenderer: (params) => `<div class='tw-h-full tw-flex tw-items-center'>${params.data.id}</div>`,
    },
    {
      headerName: 'Controller Name',
      sortable: false,
      minWidth: 190,
      cellRenderer: (params) => `<div class='tw-h-full tw-flex tw-items-center'><a class="tw-text-base tw-text-blue-600 tw-font-normal tw-rows-ellipsis" target="_blank" style="--line-clamp: 1" href="/controls/${params.data.controller.id}">${params.data.controller.name}</a></div>`,
    },
    {
      headerName: 'Event Type',
      sortable: false,
      field: 'event.event_type_as_string',
      cellRenderer: (params) => `<div class='tw-h-full tw-flex tw-items-center'>${params.data.event.event_type_as_string}</div>`,
    },
    {
      headerName: 'Description',
      wrapHeaderText: true,
      sortable: false,
      field: 'event.as_text',
      autoHeight: true,
      cellRenderer: (params) => `<div class='tw-rows-ellipsis tw-cursor-pointer hover:tw-bg-blue-100 tw-whitespace-normal tw-mb-0 tw-min-w-75' style='--line-clamp: 2;' onclick="this.classList.remove('tw-rows-ellipsis')"> ${params.data.event.as_text} </div>`,
    },
  ],

  defaultColDef: {
    flex: 1,
    minWidth: 90,
  },

  // use the server-side row model
  rowModelType: 'serverSide',

  // enable pagination
  pagination: true,

  // 10 rows per page (default is 100)
  paginationPageSize: 10,

  // fetch 20 rows per block as page size is 10 (default is 100)
  cacheBlockSize: 20,

  paginationPageSizeSelector: [10, 20, 50, 100],

  suppressRowHoverHighlight: true,
};

const gridDiv = document.querySelector<HTMLElement>('#queuedAlertsGrid');
if (gridDiv) {
  gridApi = createGrid(gridDiv, gridOptions);
  const datasource = {
    getRows(params: IServerSideGetRowsParams) {
      const requestParam = new URLSearchParams();
      requestParam.append('per_page', '20');
      requestParam.append('page', (params.request.endRow / (params.request.endRow - params.request.startRow)).toString());
      requestParam.append('timestamp', new Date().getTime().toString());
      const hmac = HmacSHA256(requestParam.toString(), window.SLNAS).toString(Base64);
      fetch(`/api/v2/users/${gridDiv.dataset.id}/user_alerts?${requestParam}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=utf-8', 'x-api-key': window.SLNAK, 'x-api-hmac': hmac },
      })
        .then((httpResponse) => httpResponse.json())
        .then((response) => {
          params.success({
            rowData: response.alerts,
            rowCount: response.meta.found,
          });
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  };

  // register datasource with the grid
  gridApi.setGridOption('serverSideDatasource', datasource);
}
