import {
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  createGrid,
  FilterModel,
  GridOptions,
  IServerSideGetRowsParams,
  ModuleRegistry,
  PaginationModule,
  ServerSideRowModelApiModule,
  ServerSideRowModelModule,
  SetFilterModule,
  ValidationModule,
} from 'ag-grid-enterprise';

import Base64 from 'crypto-js/enc-base64.js';
import HmacSHA256 from 'crypto-js/hmac-sha256.js';

ModuleRegistry.registerModules([
  PaginationModule,
  ColumnsToolPanelModule,
  ColumnMenuModule,
  ContextMenuModule,
  ServerSideRowModelModule,
  SetFilterModule,
  ServerSideRowModelApiModule,
  ValidationModule /* Development Only */,
]);

interface FilterModelSites extends FilterModel {
  type_of_access?: {
    filterType: 'set';
    values: string[];
  };
}

let gridApi = null;

function renderAccessType(value) {
  const displayNames = {
    BillingUser: window.I18n.shared.roles.billing_user,
    CustomUser: window.I18n.shared.roles.custom_user,
    BranchUser: window.I18n.shared.roles.branch_user,
    UserSite: window.I18n.shared.roles.site_user,
  };

  return displayNames[value] || value;
}

function renderPermission(enabled) {
  const icon = enabled
    ? `<svg class="tw-w-6 tw-h-6" fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
      <path clip-rule="evenodd" d="M9.9999 19.5984C15.3018 19.5984 19.5999 15.3004 19.5999 9.99844C19.5999 4.6965 15.3018 0.398438 9.9999 0.398438C4.69797 0.398438 0.399902 4.6965 0.399902 9.99844C0.399902 15.3004 4.69797 19.5984 9.9999 19.5984ZM14.4484 8.44697C14.9171 7.97834 14.9171 7.21854 14.4484 6.74991C13.9798 6.28128 13.22 6.28128 12.7514 6.74991L8.7999 10.7014L7.24843 9.14991C6.7798 8.68128 6.02 8.68128 5.55137 9.14991C5.08275 9.61854 5.08275 10.3783 5.55137 10.847L7.95137 13.247C8.42 13.7156 9.1798 13.7156 9.64843 13.247L14.4484 8.44697Z" fill="#0075A9" fill-rule="evenodd" > </path>
    </svg>`
    : `<svg class="tw-w-6 tw-h-6" fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg" >
      <path clip-rule="evenodd" d="M9.9999 19.5984C15.3018 19.5984 19.5999 15.3004 19.5999 9.99844C19.5999 4.6965 15.3018 0.398438 9.9999 0.398438C4.69797 0.398438 0.399902 4.6965 0.399902 9.99844C0.399902 15.3004 4.69797 19.5984 9.9999 19.5984ZM8.44843 6.74991C7.9798 6.28128 7.22 6.28128 6.75137 6.74991C6.28274 7.21854 6.28274 7.97834 6.75137 8.44697L8.30285 9.99844L6.75137 11.5499C6.28274 12.0185 6.28274 12.7783 6.75137 13.247C7.22 13.7156 7.9798 13.7156 8.44843 13.247L9.9999 11.6955L11.5514 13.247C12.02 13.7156 12.7798 13.7156 13.2484 13.247C13.7171 12.7783 13.7171 12.0185 13.2484 11.5499L11.697 9.99844L13.2484 8.44697C13.7171 7.97834 13.7171 7.21854 13.2484 6.74991C12.7798 6.28128 12.02 6.28128 11.5514 6.74991L9.9999 8.30138L8.44843 6.74991Z" fill="#9E9E9E" fill-rule="evenodd" > </path>
    </svg>`;

  return `<div class='tw-h-full tw-flex tw-justify-center tw-items-center'>${icon}</div>`;
}
const gridOptions: GridOptions = {
  columnDefs: [
    {
      headerName: 'Site Id', field: 'id', maxWidth: 90, wrapHeaderText: true,
    },
    {
      headerName: 'Site Name',
      field: 'name',
      minWidth: 190,
      cellRenderer: (params) => `<div class='tw-h-full tw-flex tw-items-center'><a class="tw-text-base tw-text-blue-600 tw-font-normal tw-rows-ellipsis" target="_blank" style="--line-clamp: 1" href="/sites/${params.data.id}">${params.data.name}</a></div>`,
    },
    {
      headerName: 'Access',
      field: 'type_of_access',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: ['BillingUser', 'CustomUser', 'BranchUser', 'UserSite'],
        valueFormatter: (params) => {
          const displayNames = {
            BillingUser: window.I18n.shared.roles.billing_user,
            CustomUser: window.I18n.shared.roles.custom_user,
            BranchUser: window.I18n.shared.roles.branch_user,
            UserSite: window.I18n.shared.roles.site_user,
          };
          return displayNames[params.value] || params.value;
        },
      },
      cellRenderer: (params) => renderAccessType(params.data.type_of_access),
    },
    { headerName: 'Controller Count', field: 'controllers_count', wrapHeaderText: true },
    {
      headerName: window.I18n.site_users.index.table.view_controller.replace('<br>', ' '),
      wrapHeaderText: true,
      sortable: false,
      cellRenderer: (params) => renderPermission(((params.data.user_site_permissions || params.data.branch_user_permissions || 0) & 1) >= 1), // eslint-disable-line no-bitwise
    },
    {
      headerName: window.I18n.site_users.index.table.run_commands.replace('<br>', ' '),
      wrapHeaderText: true,
      sortable: false,
      cellRenderer: (params) => renderPermission(((params.data.user_site_permissions || params.data.branch_user_permissions || 0) & 2) >= 2), // eslint-disable-line no-bitwise
    },
    {
      headerName: window.I18n.site_users.index.table.edit_programs.replace('<br>', ' '),
      wrapHeaderText: true,
      sortable: false,
      cellRenderer: (params) => renderPermission(((params.data.user_site_permissions || params.data.branch_user_permissions || 0) & 4) >= 1), // eslint-disable-line no-bitwise
    },
    {
      headerName: window.I18n.site_users.index.table.site_admin.replace('<br>', ' '),
      wrapHeaderText: true,
      sortable: false,
      cellRenderer: (params) => renderPermission(((params.data.user_site_permissions || params.data.branch_user_permissions || 0) & 8) >= 1), // eslint-disable-line no-bitwise
    },
  ],

  defaultColDef: {
    flex: 1,
    minWidth: 90,
  },

  // use the server-side row model
  rowModelType: 'serverSide',

  // enable pagination
  pagination: true,

  // 10 rows per page (default is 100)
  paginationPageSize: 10,

  // fetch 20 rows per block as page size is 10 (default is 100)
  cacheBlockSize: 20,

  paginationPageSizeSelector: [10, 20, 50, 100],

  suppressRowHoverHighlight: true,
};

const gridDiv = document.querySelector<HTMLElement>('#userSitesGrid');
if (gridDiv) {
  gridApi = createGrid(gridDiv, gridOptions);
  const siteSearch = document.getElementById('dashboard-sites-search') as HTMLInputElement;

  const datasource = {
    getRows(params: IServerSideGetRowsParams) {
      const requestParam = new URLSearchParams();
      requestParam.append('per_page', '20');
      requestParam.append('page', (params.request.endRow / (params.request.endRow - params.request.startRow)).toString());
      requestParam.append('embed_controllers', 'false');
      requestParam.append('most_recent_activation', 'false');
      if (params.request.sortModel.length > 0) {
        requestParam.append('sortCol', `${params.request.sortModel[0].colId} ${params.request.sortModel[0].sort}`);
      }

      if (siteSearch.value !== '') {
        requestParam.append('search', siteSearch.value);
      }

      if ((params.request.filterModel as FilterModelSites)?.type_of_access) {
        (params.request.filterModel as FilterModelSites).type_of_access.values.forEach((value) => {
          requestParam.append('filter[type_of_access_in][]', value);
        });
      }

      requestParam.append('timestamp', new Date().getTime().toString());
      const hmac = HmacSHA256(requestParam.toString(), window.SLNAS).toString(Base64);
      fetch(`/api/v2/users/${gridDiv.dataset.id}/user_sites?${requestParam}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json; charset=utf-8', 'x-api-key': window.SLNAK, 'x-api-hmac': hmac },
      })
        .then((httpResponse) => httpResponse.json())
        .then((response) => {
          params.success({
            rowData: response.sites,
            rowCount: response.meta.found,
          });
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  };

  // register datasource with the grid
  gridApi.setGridOption('serverSideDatasource', datasource);

  siteSearch.addEventListener(
    'keyup',
    window.debounce(
      () => {
        gridApi.refreshServerSide();
      },
      500,
      false,
    ),
  );
}
