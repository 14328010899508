function inspectionWithIssues() {
  const inspectionId = document.getElementById('inspection').dataset.inspectionId;
  const controllerId = document.getElementById('inspection').dataset.controllerId;
  window.$.ajax({
    type: 'GET',
    dataType: 'script',
    url: `/controls/${controllerId}/inspections/${inspectionId}/presents_issues`,
  });
}
window.inspectionWithIssues = inspectionWithIssues;

function updateInspectionStatus(inspectionStatus) {
  const inspectionId = document.getElementById('inspection').dataset.inspectionId;
  const controllerId = document.getElementById('inspection').dataset.controllerId;
  window.$.ajax({
    type: 'PUT',
    dataType: 'script',
    url: `/controls/${controllerId}/inspections/${inspectionId}`,
    data: { inspection_status: inspectionStatus },
  });
}
window.updateInspectionStatus = updateInspectionStatus;

function zoneCommentUpdated(element) {
  const input = document.getElementById(`${element}-comment`);
  const title = document.getElementById(`${element}-title`);
  if (input.value.length > 0) {
    input.classList.add('tw-border-gray-600');
    input.classList.remove('tw-border-gray-300');
    title.classList.add('tw-text-gray-900');
    title.classList.remove('tw-text-gray-600');
  } else {
    input.classList.remove('tw-border-gray-600');
    input.classList.add('tw-border-gray-300');
    title.classList.remove('tw-text-gray-900');
    title.classList.add('tw-text-gray-600');
  }
}
window.zoneCommentUpdated = zoneCommentUpdated;

function buildZoneCommentRequest() {
  const comment = {};
  window.INSPECTION_ISSUES.forEach((element) => {
    const value = document.getElementById(`${element}-comment`)?.value;
    if (value?.length > 0) {
      comment[element] = value;
    }
  });
  return comment;
}

function buildZoneComment(response) {
  let comment = '';
  window.INSPECTION_ISSUES.forEach((element) => {
    if (response[element]?.length > 0) {
      const title = element === 'nozzle' ? window.I18n.inspections.zone_issues.table_columns.clogged_nozzle : window.I18n.inspections.zone_issues.table_columns[element];
      comment += `[${title}] ${response[element]} `;
    }
  });
  return comment;
}
function updateZoneComments(inspectionId, inspectionZoneId) {
  window.$.ajax({
    url: `/api/v2/inspections/${inspectionId}/inspections_zones/${inspectionZoneId}/update_comments`,
    data: JSON.stringify({ inspections_zone: { note: document.getElementById('zone_general_comment').value, issues_note: buildZoneCommentRequest() } }),
    dataType: 'json',
    type: 'PUT',
    contentType: 'application/json; charset=utf-8',
    success(response) {
      document.getElementById('inspection_zone_comments-modal').classList.add('tw-hidden');
      document.querySelector(`.zone-comment-trigger[data-id="${inspectionZoneId}"]`).innerText = `${response.note} ${buildZoneComment(response.issues_note)}`;
    },
  });
}
window.updateZoneComments = updateZoneComments;

function addInspectionZoneComment(element) {
  const comment = element.parentElement.parentElement.querySelector('.zone-comment').innerText;
  document.getElementById('zone_general_comment').value = comment;
}
window.addInspectionZoneComment = addInspectionZoneComment;
