function removePaymentSource(paymentSourceId, newPaymentSourceId) {
  window.$.ajax({
    type: 'DELETE',
    url: `/payment_sources/${paymentSourceId}`,
    dataType: 'script',
    data: { new_payment_source_id: newPaymentSourceId },
  });
}

function replacePaymentSourceSubscription() {
  const button = document.querySelector('#delete_payment_source_subscriptions_modal  .delete_update');
  if (document.querySelector('#edit_payment_sources_dropdown .li-check')) {
    button.classList.remove('tw-btn-disabled');
    button.classList.add('tw-button-primary');
  } else {
    button.classList.add('tw-btn-disabled');
    button.classList.remove('tw-button-primary');
  }
}
window.replacePaymentSourceSubscription = replacePaymentSourceSubscription;

function renderDeleteWithSubscriptions(paymentSourceId, sourceType, element) {
  const deleteModal = document.getElementById('delete_payment_source_subscriptions_modal');
  if (sourceType === 'cards') {
    deleteModal.querySelector('.title').innerHTML = window.I18n.payment_sources.delete_card_modal.title;
    deleteModal.querySelector('.description').innerHTML = window.I18n.payment_sources.delete_with_subscriptions.description_card.replace('%{last4}', element.dataset.last4).replace('%{subscriptions}', element.dataset.subscriptions);
  } else {
    deleteModal.querySelector('.title').innerHTML = window.I18n.payment_sources.delete_bank_account_modal.title;
    deleteModal.querySelector('.description').innerHTML = window.I18n.payment_sources.delete_with_subscriptions.description_bank_account.replace('%{last4}', element.dataset.last4).replace('%{subscriptions}', element.dataset.subscriptions);
  }
  document.querySelector('#edit_payment_sources_dropdown button .dropdown-content').textContent = window.I18n.payment_sources.delete_with_subscriptions.select_payment;
  const checked = document.querySelector('#edit_payment_sources_dropdown .li-check');
  if (checked) {
    checked.children[0].classList.add('tw-hidden');
    checked.classList.remove('li-check');
    checked.classList.add('li-no-check');
  }
  document.querySelectorAll('#edit_payment_sources_dropdown li.tw-hidden').forEach((option) => {
    option.classList.remove('tw-hidden');
  });
  document.getElementById(`edit_payment_sources_dropdown-${paymentSourceId}`).classList.add('tw-hidden');
  deleteModal.classList.remove('tw-hidden');
  deleteModal.querySelector('.tw-button-delete').onclick = () => removePaymentSource(paymentSourceId);
  deleteModal.querySelector('.delete_update').onclick = () => {
    const payment = document.querySelector('#edit_payment_sources_dropdown .li-check');
    removePaymentSource(paymentSourceId, payment.dataset.id);
  };
  replacePaymentSourceSubscription();
}

function showDestroyPaymentSourceModal(paymentSourceId, sourceType) {
  const element = document.getElementById(`payment_source-${paymentSourceId}`);
  const subscriptions = parseInt(element.dataset.subscriptions, 10);
  if (subscriptions > 0) {
    renderDeleteWithSubscriptions(paymentSourceId, sourceType, element);
  } else {
    const confirmationModal = document.getElementById('remove_payment_source');
    confirmationModal.classList.remove('tw-hidden');
    if (sourceType === 'cards') {
      confirmationModal.querySelector('.title').innerHTML = window.I18n.payment_sources.delete_card_modal.title;
      confirmationModal.querySelector('.description').innerHTML = window.I18n.payment_sources.delete_card_modal.description.replace('%{last4}', element.dataset.last4);
    } else {
      confirmationModal.querySelector('.title').innerHTML = window.I18n.payment_sources.delete_bank_account_modal.title;
      confirmationModal.querySelector('.description').innerHTML = window.I18n.payment_sources.delete_bank_account_modal.description.replace('%{last4}', element.dataset.last4);
    }
    confirmationModal.querySelector('.tw-btn-action-red').onclick = () => removePaymentSource(paymentSourceId);
  }
}
window.showDestroyPaymentSourceModal = showDestroyPaymentSourceModal;
